.download {
  padding: 16vw 5.33333vw 2.66667vw;
  text-align: center;
  box-sizing: border-box; }
  .download h2 {
    font-size: 7.46667vw;
    color: #3f3f3f;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.53333vw; }
  .download h3 {
    font-size: 4.8vw;
    color: #3f3f3f; }

.summary {
  font-size: 5.06667vw;
  color: #3f3f3f;
  opacity: 0.8; }

.animate {
  width: 28.53333vw;
  height: 32vw;
  position: relative;
  margin: 18.66667vw auto 18.66667vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }

@keyframes makeRotate {
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

.link {
  color: #2470ec;
  text-decoration: underline;
  padding: 0 1.33333vw; }

.download_wrapper {
  margin-top: 10.66667vw;
  margin-bottom: 2.66667vw; }

.download_btn {
  width: 40.8vw;
  height: 13.33333vw;
  display: block;
  line-height: 13.33333vw;
  color: #fff;
  font-size: 3.73333vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.25s;
  border-radius: 1.06667vw;
  position: relative;
  font-weight: normal;
  background: #ff6101; }
  .download_btn:global(.active) {
    background-image: url(/download/do.png), linear-gradient(270deg, #ffbc12 10%, #ffbc12 100%);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain;
    color: #433833; }
  .download_btn:global(.vip) {
    background: #2470ed url(/vip.png) no-repeat right top;
    background-size: contain; }
  .download_btn span {
    width: 4.26667vw;
    height: 4.26667vw;
    border-radius: 100%;
    border-width: 0.53333vw;
    border-style: solid;
    vertical-align: middle;
    border-color: rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.2) white white;
    margin-right: 2.13333vw;
    visibility: hidden;
    transform: rotate(0);
    display: none;
    box-sizing: border-box; }
  .download_btn.loading {
    opacity: 0.8;
    transition: 0.25s; }
    .download_btn.loading span {
      display: block;
      visibility: visible;
      opacity: 1;
      animation: loadingState 1.2s infinite;
      -webkit-animation: loadingState 1.2s infinite; }

.apple_beta {
  margin-top: 5.06667vw;
  color: #3977ff;
  font-size: 3.73333vw; }

.otherDownlod {
  font-size: 4.8vw;
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
  line-height: 1;
  margin: 0;
  background: #000 url(/download/ios.svg) no-repeat 4.26667vw center;
  background-size: 7.46667vw;
  text-decoration: none;
  padding: 1.6vw 1.6vw 1.6vw 13.33333vw;
  text-align: left;
  border-radius: 1.06667vw;
  margin-right: 2.66667vw;
  width: 49%;
  box-sizing: border-box; }
  .otherDownlod:global(.download-default) {
    background-color: #aaa; }
    .otherDownlod:global(.download-default) small {
      letter-spacing: 2.66667vw; }
  .otherDownlod small {
    font-size: 3.2vw;
    display: block;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 1.33333vw; }

.otherDownlod + .download_btn {
  width: 49%;
  box-sizing: border-box; }

@keyframes loadingState {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes loadingState {
  100% {
    transform: rotate(360deg); } }

.strength {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.73333vw;
  color: #3f3f3f; }
  .strength span {
    display: inline-block;
    padding: 0 1.33333vw;
    border-right: 1px solid #ff6101;
    line-height: 1; }
    .strength span:last-child {
      border-right: none; }

.download_link {
  visibility: hidden;
  opacity: 0; }

.shadow,
.ucShadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 111;
  background-color: rgba(0, 0, 0, 0.8);
  background-position: right 2.66667vw top 2.66667vw;
  background-repeat: no-repeat;
  background-size: 50%; }

.shadow {
  background-image: url("/tips.png"); }
  .shadow.android {
    background-image: url("/tips_android.png"); }

.ucShadow {
  background-image: url("/tips-uc.png"); }

.install {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
  text-align: left;
  line-height: 1.5;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }
  .install img {
    display: block;
    margin: 0 auto;
    min-height: 12vw;
    position: relative;
    margin: 2.66667vw 0;
    left: -4vw; }
  .install p {
    margin-top: 4vw;
    line-height: 2;
    padding: 1.33333vw 0;
    font-size: 4vw; }
  .install > div {
    background: #fff;
    padding: 4vw;
    position: relative;
    width: 100%;
    box-sizing: border-box; }

.step_detail {
  padding-bottom: 8vw;
  background: #fff;
  padding: 5.33333vw; }
  .step_detail p {
    line-height: 1.5;
    padding: 2.13333vw 0;
    font-size: 3.73333vw; }
  .step_detail img {
    display: block;
    margin: 0 auto;
    margin-bottom: 5.33333vw; }
  .step_detail h5 {
    font-size: 5.33333vw;
    font-weight: bold;
    margin-bottom: 4vw; }

.confirm {
  background: #000;
  display: block;
  color: #fff;
  line-height: 11.73333vw;
  text-align: center;
  font-weight: bold; }
  .confirm i {
    display: inline-block;
    vertical-align: middle;
    width: 6.93333vw;
    height: 6.93333vw;
    background: url(/download/app_store.png) no-repeat center center;
    background-size: contain;
    margin-right: 2.66667vw; }

.close {
  position: absolute;
  right: 2.66667vw;
  top: 2.66667vw; }

.fixed_bottom {
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background: rgba(255, 255, 255, 0.9);
  padding: 2.66667vw !important;
  box-sizing: border-box;
  box-shadow: 0 0 1.33333vw rgba(0, 0, 0, 0.15); }

.icon_seting {
  display: inline-block;
  width: 6.4vw;
  height: 6.4vw;
  vertical-align: middle;
  background: url("/download/t_s.png") no-repeat center center;
  background-size: contain; }

.account_list {
  white-space: nowrap;
  margin: 2.66667vw 0 5.33333vw;
  background: #f1f8fc;
  border: 1px solid #c2dff3;
  color: #1882d4;
  width: 100%;
  text-align: left;
  border-collapse: collapse; }
  .account_list p {
    padding: 1.33333vw 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .account_list span {
    padding: 0 2.66667vw;
    line-height: 1.5;
    color: #1882d4;
    border-radius: 1.06667vw;
    border: 1px solid #1882d4; }
  .account_list li {
    padding: 2.66667vw;
    position: relative; }
    .account_list li:not(:last-child) {
      border-bottom: 1px solid #c2dff3; }
    .account_list li:after {
      display: block;
      content: "";
      width: 1px;
      height: 60%;
      background: #c2dff3;
      position: absolute;
      right: 21.33333vw;
      top: 50%;
      transform: translateY(-50%); }

.list_icon {
  width: 6.93333vw;
  height: 6.93333vw;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background: #2470ec linear-gradient(90deg, #2470ec, #fff);
  color: #fff;
  text-align: center;
  line-height: 6.93333vw; }

.rules {
  margin-bottom: 16vw;
  line-height: 2;
  color: #1882d4; }

.local_banner {
  height: 145.6vw;
  background: #fff url(/download/bg.png) no-repeat center top;
  background-size: contain;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  padding-top: 10.66667vw;
  font-size: 3.73333vw; }
  .local_banner .atten {
    font-size: 3.2vw;
    color: rgba(255, 255, 255, 0.8); }
  .local_banner a {
    display: block;
    width: 37.33333vw;
    height: 12.26667vw;
    margin: 0.53333vw auto;
    color: #3f3f3f;
    background: #fff;
    border-radius: 1.06667vw;
    line-height: 12.26667vw;
    font-size: 4.26667vw;
    box-shadow: 0 1.06667vw 8vw 0 rgba(181, 71, 18, 0.5); }
    .local_banner a:global(.vip) {
      background: #fff url(/vip.png) no-repeat right top;
      background-size: contain; }
  .local_banner h2 {
    font-size: 6.4vw;
    font-weight: bold; }
    .local_banner h2:before {
      display: block;
      content: "";
      width: 18.66667vw;
      height: 18.66667vw;
      background: url(/download/logo.png) no-repeat center center;
      background-size: contain;
      margin: 0 auto 2.66667vw; }

.local_body {
  padding: 8vw;
  font-size: 4.26667vw;
  color: #031b4e;
  background: #fff; }
  .local_body img {
    display: block;
    margin: 5.33333vw auto;
    max-width: 100%; }
  .local_body .exam_1 {
    width: 100%;
    margin-top: 5.33333vw;
    height: 83.46667vw;
    background: url("/download/exam1.png") no-repeat center;
    background-size: contain; }
  .local_body .exam_2 {
    width: 100%;
    height: 106.93333vw;
    margin-top: 5.33333vw;
    background: url("/download/exam2.png") no-repeat center;
    background-size: contain; }
  .local_body h3 {
    font-size: 3.73333vw;
    text-align: center;
    margin-bottom: 5.33333vw;
    color: #202020; }
  .local_body p:last-child {
    margin-top: 5.33333vw; }
  .local_body p {
    font-size: 3.73333vw;
    color: #4f4f4f;
    text-align: center; }
  .local_body b {
    color: #326ef1;
    font-weight: normal; }
    .local_body b.symbol {
      vertical-align: middle;
      padding-right: 3.73333vw;
      background: url(/download/arrow_right.png) no-repeat right center;
      background-size: 3.2vw; }

.ios_down {
  display: flex; }
  .ios_down .download_btn_store {
    background: #000;
    width: 84vw;
    height: 14.4vw;
    color: #fff;
    font-size: 3.46667vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.06667vw;
    position: relative;
    margin-left: 5.33333vw; }
    .ios_down .download_btn_store .icon {
      width: 25.6vw;
      height: 100%;
      background: url("/download/do.png") no-repeat center;
      background-size: 100% 100%; }
    .ios_down .download_btn_store .icon_ios {
      width: 5.33333vw;
      height: 5.33333vw;
      background: url("/download/ios.svg") no-repeat center;
      background-size: 100% 100%;
      margin-left: 24.26667vw; }
    .ios_down .download_btn_store .text {
      position: absolute;
      font-size: 4.26667vw;
      left: 32.53333vw;
      top: 4.26667vw;
      line-height: 5.86667vw; }
